import React from 'react';
import "./ConsultingHome.css";
import ConsultingFooter from "./ConsultingFooter";
import ConsultingCards from "./ConsultingCards";
import ConsultingDescription from "./ConsultingDescription";
import ConsultingHero from  "./ConsultingHero";
import ConsultingNavbar from "./ConsultingNavbar";
import ConsultingTitle from "./ConsultingTitle";

const ConsultingHome = () => {
  return (
		<>
			<ConsultingTitle />
			<ConsultingNavbar />
			<ConsultingHero />
			<ConsultingDescription />
			<ConsultingCards />
			<ConsultingFooter />
		</>
		);
};

export default ConsultingHome;