import React from 'react'
import './ConsultingFooter.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import braun_consulting_group_icon_32x32 from '../../../images/braun_consulting_group_icon_32x32.jpg'

function ConsultingFooter() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Join the newsletter to receive updates and our seasonal deals.
        </p>
        <p className='footer-subscription-text'>
          You can unsubscribe at any time.
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--outline'>Subscribe</Button>
          </form>
        </div>
      </section>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/braun-consulting-group/contact'>How it works</Link>
            <Link to='/braun-consulting-group/contact'>Terms of Service</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/braun-consulting-group/contact'>Contact</Link>
            <Link to='/braun-consulting-group/contact'>Services</Link>
            <Link to='mailto:braun-consulting-group@7071997.xyz'>
              Email Us
            </Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <Link to='/braun-consulting-group/contact'>Youtube</Link>
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/braun-consulting-group/contact' className='social-logo'>
              Braun Consulting
              <img src={braun_consulting_group_icon_32x32} alt='Braun Consulting Group Icon' />
            </Link>
          </div>
          <small class='website-rights'>Braun Consulting Group © 2020-2023</small>
            <Link
              class='social-icon-link youtube'
              to='/braun-consulting-group/contact'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
          </div>
        </section>
    </div>
  );
}

export default ConsultingFooter;