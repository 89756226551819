import React from 'react';
import {Helmet} from "react-helmet";

function ConsultingTitle(){
	return (
     <div className="application">
          <Helmet>
              <meta charSet="utf-8" />
              <title>BCG</title>
              <link rel="canonical" href="http://7071997.xyz/braun-consulting-group/" />
              <link rel="icon" type="consulting_favicon.ico" href="/consulting_favicon.ico" sizes="16x16" />
          </Helmet>
      </div>
    );
}

export default ConsultingTitle;