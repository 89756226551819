import React from 'react'
import './ConsultingApp.css'
import './ConsultingDescription.css';

function ConsultingDescription(){
	return(
		<div className = 'description-container' >
            <p>We are an engineering firm made up of ex-FAANG employees. The firm has previous experience designing and implementing 
            software for Amazon home-security features, delivering Android and Cloud features for acoustic event 
            detection, Live View, and Alexa Devices & Services Development & Integration using AWS, Spring, and Java. We've led performance, 
            security, and test enhancements for critical messaging systems used across DeviceOS & Amazon Common Software SDK, impacting millions of 
            Alexa / Echo Based devices. Our team also has experience in FPGA design and verification, digital communications, and satellite research. </p>
            
            <p>Our team has proven experience in Android, Apollo, AWS, Bash, C, C++, CSS, Git, Java, Jira, MATLAB, Machine Learning, Python, RTOS, Shell, SQL, 
            UNIX / Linux. Our team is comprised of Subject Matter Experts who have at least 3+ years of experience delivering high-quality software and hardware to our customers. </p>
		</div>
		);
}

export default ConsultingDescription;