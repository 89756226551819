import React from 'react';
import {Helmet} from "react-helmet";

function Dota2Title(){
	return (
     <div className="application">
          <Helmet>
              <meta charSet="utf-8" />
              <title>Dota 2 Boosting</title>
              <link rel="canonical" href="http://7071997.xyz/dota2/" />
              <link rel="icon" type="dota2_favicon.ico" href="/dota2_favicon.ico" sizes="16x16" />
          </Helmet>
      </div>
    );
}

export default Dota2Title;