import React from 'react'
import '../App.css'
import {Button} from './Button';
import './HeroSection.css';

function HeroSection() {
	return (
		<div className='hero-container'>
			<video src="/videos/dota2UltraKill_lion.mp4" autoPlay loop muted />
			<h1> GAIN YOUR DOTA2 MMR NOW </h1>
			<div className="hero-btns">
				<Button className='btns' 
				buttonStyle='btn--outline'
				buttonSize='btn--large'>
					GET STARTED
				</Button>
				<Button className='btns' 
				buttonStyle='btn--primary'
				buttonSize='btn--large'>
					WATCH INFO VIDEO <i className='far fa-play-circle' />
				</Button>
			</div>
		</div>
		);
}

export default HeroSection;