import React from 'react';

class Resume extends React.Component {
  componentDidMount() {
    window.location.href = 'https://docs.google.com/document/d/11BAf5_2SFid-LB6HnRZ_5ra-FsqO_Ls5/edit?usp=sharing&ouid=108988336572084672672&rtpof=true&sd=true';
  }

  render() {
    return (
      <div>
        Redirecting...
      </div>
    );
  }
}

export default Resume;