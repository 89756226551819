import React from 'react'
import AbsoluteCardItem from '../../AbsoluteCardItem'
import '../../Cards.css'
import { Link } from 'react-router-dom'

function ConsultingCards() {
    return (
        <div className='cards'>
            <h1>Welcome to Braun Consulting Group</h1> <br />
                <div className="cards__container">
                    <div className="cards__wraper">
                    <h2>We specialize in Electrical Engineering, Software Engineering, Construction Management, Management Consulting, and Saltwater Conservation.</h2>
                    <p>Let us help you with your next project.</p>
                    <Link className="btn btn-primary btn-lg" to="/braun-consulting-group/contact" role="button">
                    Get in Touch
                    </Link>
                </div>
            <ul className="cards__items">
                <AbsoluteCardItem
                    src={'https://media.tenor.com/O-Tgj4NaXUcAAAAC/coralreef.gif'}
                    text="Saltwater Conservation & Consulting: Our saltwater conservation team can help you maintain and grow saltwater aquaria."
                    label="Saltwater Consulting"
                    path='/braun-consulting-group/contact'
                    />
                <AbsoluteCardItem
                    src={process.env.PUBLIC_URL + '/images/braun-consulting-group-card.jpg'}
                    text="Management Consulting: Our management consulting services can help you improve your business operations."
                    label="Management Consulting"
                    path='/braunn-consulting-group/contact'
                />
                <AbsoluteCardItem
                    src={"https://www.huffcon.com/wp-content/uploads/2016/06/Construction-PM-1170x768.jpg"}
                    text="Construction Management: We offer construction management services to help you with your next project."
                    label="Construction Management"
                    path='/braun-consulting-group/contact'
                />
                <AbsoluteCardItem
                    src={"https://media.tenor.com/2fXbn6Xtt0UAAAAM/software-software-development.gif"}
                    text="Software Engineering: Our software engineering team can help you with all your software needs."
                    label="Software Engineering"
                    path='/braun-consulting-group/contact'
                    />
                <AbsoluteCardItem
                    src={"https://images.ctfassets.net/mviowpldu823/3U3OSK4gMDUVY4ZbHbJaoB/7236f6a2c09eeaaa703cbde8f9f0c1d4/SES-electric-engineer-288_0618.jpg"}
                    text="Electrical Engineering: We offer a wide range of electrical engineering services to help you with your next project."
                    label="Electrical Engineering"
                    path='/braun-consulting-group/contact'
                />
            </ul>
            <h2>Proven Experience: Past Projects for Customers</h2>
            <ul className="cards__items">
                    <AbsoluteCardItem
                    src={process.env.PUBLIC_URL + '/images/past_project_gqrx.jpg'}
                    text="Doubled supported bandwidth for ELFIN Cube-SAT"
                    label="GQRX C++ App Dev"
                    path='https://github.com/AndrewBraunEE/gqrx-dual-bandpass-filter'
                    />
                    <AbsoluteCardItem
                    src={process.env.PUBLIC_URL + '/images/past_project_elfin_groundstation.jpeg'}
                    text="Automated Cube-Satellite Tracking for Ground-Station & Science Data Collection"
                    label="HW & SW Cube-Sat Experience"
                    path='https://www.eoportal.org/satellite-missions/elfin#eop-quick-facts-section'
                    />
                    <AbsoluteCardItem
                    src={"https://cdn.everythingrf.com/live/1610278761571_637458755466105219.jpeg"}
                    text="RF & Communications Stress-Testing and Quality Assurance"
                    label="RF & Communication Systems"
                    path='https://github.com/AndrewBraunEE/gqrx-dual-bandpass-filter'
                    />
                    <AbsoluteCardItem
                    src={process.env.PUBLIC_URL + '/images/past_project_graduation_cap.jpg'}
                    text="Created a IOT synchronized graduation cap display"
                    label="IOT EECS HW & SW"
                    path='https://github.com/il-dionigi/180DB-GradCaps'
                    />
                </ul>
                <ul className="cards__items">
                    <AbsoluteCardItem
                    src={"https://www.researchgate.net/profile/Cameron-Mura/publication/264005309/figure/fig3/AS:296569900421125@1447719182544/Inter-process-communication-IPC-between-pipers-p1-p2-The-dashed-arrow-illustrates.png"}
                    text="Embedded Software Engineering for Amazon Devices"
                    label="C / C++ / Java / Rust Dev"
                    path='https://www.techhive.com/article/1804187/echo-show-5-third-generation-spotted.html'
                    />
                    <AbsoluteCardItem
                    src={"https://cdn.vox-cdn.com/thumbor/cby7DyhcMGoaFu4iyziqLmbKknY=/0x0:3000x2000/1400x1050/filters:focal(1500x1000:1501x1001)/cdn.vox-cdn.com/uploads/chorus_asset/file/22882959/amazon_astro_home_robot_4773_5.jpg"}
                    text="Created LiveView & Sentry Experiences for Amazon Astro Robot"
                    label="Amazon Astro Java Dev"
                    path='https://www.youtube.com/watch?v=SREMGn5BNtI'
                    />
                    <AbsoluteCardItem
                    src={"https://www.mouser.com/images/marketingid/2019/img/169028107.png?v=033123.0815"}
                    text="Created TMR (Triple Modular Replication) FPGA Design with SEFI Scrubbing to meet SEE requirements"
                    label="FPGA Design & Verification"
                    path='https://link.springer.com/chapter/10.1007/978-3-642-24154-3_14'
                    />
                    <AbsoluteCardItem
                    src={process.env.PUBLIC_URL + '/images/past_project_rl_gossip.png'}
                    text="Created Reinforcement Learning Communications System using Software Defined Radio"
                    label="SDR Reinforcement Learning"
                    path='https://scholar.google.com/citations?user=cQDDqqUAAAAJ&hl=en'
                    />
                </ul>
                <ul className="cards__items">
                    <AbsoluteCardItem
                    src={"https://storage.googleapis.com/indie-hackers.appspot.com/content/stock-trading-bot__revenue-reports.jpg"}
                    text="Created a Deep Learning Trading Bot that achieved 24% profit in paper-trading"
                    label="Deep Learning Trading Bot"
                    path='braun-consulting-group/contact'
                    />
                    <AbsoluteCardItem
                    src={"https://img.freepik.com/free-vector/hand-drawn-flat-people-talking_23-2149053959.jpg"}
                    text="Created a CNN that distinguished non-native English Speakers vs native English Speakers using MFCC features"
                    label="CNN English Speaker Detection"
                    path='https://github.com/AndrewBraunEE/EE114HWandProjects/blob/master/Project/notebooks/Speech%20Detectionv3.ipynb'
                    />
                    <AbsoluteCardItem
                    src={"https://www.reef2reef.com/attachments/aquariumspecialty_fragtank-jpeg.3080689/"}
                    text="Reef Tank: Coral Farm"
                    label="Coral Farms"
                    path='https://www.reef2reef.com/attachments/c7d7c639-29cb-4510-8eb6-609cce865315-jpeg.927973/'
                    />
            </ul>
            <h2>Contact us for a consult</h2>
            <ul className="cards__items">
                <AbsoluteCardItem
                    src={"https://imageio.forbes.com/specials-images/imageserve/5e56f223d378190007f46149/A-management-consulting-career--How-to-build-a-career-as-a-management-consultant-/960x0.jpg?format=jpg&width=960"}
                    text="Request a Consult"
                    label="Request a Consult"
                    path='/braun-consulting-group/contact'
                    />
            </ul>
        </div>
    </div>
    );
}

export default ConsultingCards;