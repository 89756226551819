import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Footer from '../Footer';
import Navbar from '../Navbar';
import Dota2Title from '../Dota2Title';
import './SignUp.css'; // Import the CSS file
import '../../App.css';

//const nodemailer = require('nodemailer');

const Signup = () => {
	const [email, setEmail] = useState('');
	const [secondEmail, setSecondEmail] = useState('');
	const [username, setUsername] = useState('');
	// eslint-disable-next-line
	const [password, _setPassword] = useState('');
	const [reason, setReason] = useState('');
	const history = useHistory();
	
	const handleSignup = async (e) => {
		alert('Thank you for signing up! We will contact you directly.');

		try {
			// Send the form data to the server-side endpoint
			const response = await fetch('/dota2/signup', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ email, secondEmail, username, password, reason }),
			});

			// Handle the response
			const data = await response.json();
			console.log('Response:', data);
			history.push('/dota2');
		} catch (error) {
			console.error(error);
		}
	};
  
	return (
			<div className='signup'>
				<Dota2Title />
				<Navbar />
				<h2 style={{textAlign: "center"}}>Signup</h2>
				<form onSubmit={handleSignup}>
				<label htmlFor='email'>Email</label>
				<input
					type='email'
					id='email'
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					required
				/>
				<label htmlFor='second-email'>Alternate Second Email</label>
				<input
					type='email'
					id='second-email'
					value={secondEmail}
					onChange={(e) => setSecondEmail(e.target.value)}
				/>
				<label htmlFor='username'>Steam Username</label>
				<input
					type='text'
					id='username'
					value={username}
					onChange={(e) => setUsername(e.target.value)}
					required
				/>
				<label htmlFor='reason'>Reason for Signing Up</label>
				<textarea
					id='reason'
					value={reason}
					onChange={(e) => setReason(e.target.value)}
					style={{ height: '21rem' }}
				></textarea>
				<button type='submit'>Signup</button>
				</form>
				<Footer />
			</div>
	);
  };
  
  export default Signup;