import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import './ConsultingApp.css'; // Import the CSS file
import ConsultingNavbar from './ConsultingNavbar';
import ConsultingTitle from "./ConsultingTitle";
import Footer from './ConsultingFooter';
//const nodemailer = require('nodemailer');


const Contact = () => {
	const [email, setEmail] = useState('');
	const [secondEmail, setSecondEmail] = useState('');
	const [reason, setReason] = useState('');

	const history = useHistory();
	
	const handleContactRequest = async (e) => {
		alert('Thank you for requesting a consult! We will contact you directly within 48 hours.');

		try {
			// Send the form data to the server-side endpoint
			const response = await fetch('/braun-consulting-group/contact-submission', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ email, secondEmail, reason }),
			});

			// Handle the response
			const data = await response.json();
			console.log('Response:', data);
			history.push('/braun-consulting-group');
		} catch (error) {
			console.error(error);
		}
	};
  
	return (
			<div className='contact'>
			<ConsultingTitle />
			<ConsultingNavbar />
				<h2 style={{textAlign: "center"}}>Contact Form</h2>
				<form onSubmit={handleContactRequest}>
				<label htmlFor='email'>Email</label>
				<input
					type='email'
					id='email'
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					required
				/>
				<label htmlFor='second-email'>Alternate Second Email</label>
				<input
					type='email'
					id='second-email'
					value={secondEmail}
					onChange={(e) => setSecondEmail(e.target.value)}
				/>
				<label htmlFor='reason'>Consult Introduction</label>
				<textarea
					id='reason'
					value={reason}
					onChange={(e) => setReason(e.target.value)}
					style={{ height: '21rem' }}
				></textarea>
				<button type='submit'>Send</button>
				</form>
			<Footer/>
			</div>
	);
  };
  
  export default Contact;