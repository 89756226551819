import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Cards from '../Cards';
import Footer from '../Footer';
import MainDescription from '../MainDescription'
import Navbar from '../Navbar';
import Dota2Title from '../Dota2Title'


function Home(){
	return (
		<>
			<Dota2Title />
			<Navbar />
			<HeroSection />
			<MainDescription />
			<Cards />
			<Footer />
		</>
		);
}

export default Home;