import React from 'react'
import './ConsultingApp.css'
import {Button} from './Button';
import './ConsultingHero.css';

function ConsultingHero() {
	return (
		<div className='hero-container'>
			<video src="/videos/website_compilation_noaudio.mp4" autoPlay loop muted />
			<h1> Request a Consultation Today </h1>
			<div className="hero-btns">
				<Button className='btns' 
				buttonStyle='btn--outline'
				buttonSize='btn--large'>
					Get Started
				</Button>
				<Button className='btns' 
				buttonStyle='btn--primary'
				buttonSize='btn--large'>
					Watch Informational Video <i className='far fa-play-circle' />
				</Button>
			</div>
		</div>
		);
}

export default ConsultingHero;