import React from 'react'
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import dota2_32x32 from '../images/dota2_32x32.png'

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Join the newsletter to receive updates and our seasonal deals.
        </p>
        <p className='footer-subscription-text'>
          You can unsubscribe at any time.
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--outline'>Subscribe</Button>
          </form>
        </div>
      </section>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/dota2/sign-up'>How it works</Link>
            <Link to='/dota2'>Terms of Service</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/dota2'>Contact</Link>
            <Link to='/dota2'>Services</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Marketplace</h2>
            <Link to='/dota2/sign-up'>Become a Booster</Link>
            <Link to='/dota2/sign-up'>Become a Coach</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <Link to='/dota2'>Youtube</Link>
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/dota2' className='social-logo'>
              Dota 2 Services
              <img src={dota2_32x32} alt='Dota 2 Icon' />
            </Link>
          </div>
          <small class='website-rights'>Dota2 Services © 2020-2023</small>
            <Link
              class='social-icon-link youtube'
              to='/dota2'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
          </div>
        </section>
    </div>
  );
}

export default Footer;