//import logo from './logo.svg';
import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Home from './components/pages/Home';
import Services from './components/pages/Services';
import Products from './components/pages/Products';
import SignUp from './components/pages/SignUp';
import Login from './components/pages/Login';
import Boosting from './components/pages/boosting';
import behaviorAuto from './components/pages/behaviorAuto';
import behaviorReal from './components/pages/behaviorReal';
import dotaCheat from './components/pages/dotaCheat';
import serviceCoach from './components/pages/serviceCoach';
import registerCoach from './components/pages/registerCoach';
import Resume from './components/pages/Resume';

import ConsultingHome from './components/pages/Consulting/ConsultingHome';
import ConsultingContact from "./components/pages/Consulting/ConsultingContact";

//7071997.xyz
function App() {
  return (
    <>
      <Router>
          <Switch>
            <Route exact path="/resume" component = {Resume} />
            
            <Route exact path="/braun-consulting-group">
              <Redirect to="/braun-consulting-group/home" />
            </Route>
            <Route exact path="/bcg">
              <Redirect to="/braun-consulting-group/home" />
            </Route>
            <Route exact path="/consult">
              <Redirect to="/braun-consulting-group/home" />
            </Route>
            <Route exact path="/consulting">
              <Redirect to="/braun-consulting-group/home" />
            </Route>

            <Route path='/braun-consulting-group/home' exact component = {ConsultingHome} />
            <Route path='/braun-consulting-group/contact' exact component = {ConsultingContact} />

            <Route exact path="/dota">
              <Redirect to="/dota2" />
            </Route>
            <Route path='/dota2' exact component = {Home}/>
            <Route path ='/dota2/services' component={Services} />
            <Route path='/dota2/products' component={Products} />
            <Route path='/dota2/sign-up'  component={SignUp} />
            <Route path='/dota2/login'    component={Login} />
            <Route path='/dota2/boosting' component={Boosting} />
            <Route path='/dota2/behaviorAuto' component={behaviorAuto} />
            <Route path='/dota2/behaviorReal' component={behaviorReal} />
            <Route path='/dota2/dotaCheat' component={dotaCheat} />
            <Route path='/dota2/serviceCoach' component={serviceCoach} />
            <Route path='/dota2/registerCoach' component={registerCoach} />
          </Switch>
        </Router>
   </>
  );
}

export default App;