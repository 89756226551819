import React from 'react'
import '../App.css'
import './MainDescription.css';

function MainDescription(){
	return(
		<div className = 'description-container' >
			<p>‣Are you absolutely <b>sick and tired</b> of your teammates ruining your games? <br />
			‣Does <b>Valve matchmaking</b> and behavior-score hell tilt the fuck out of you? <br />
			‣Do you want a higher-skilled player to <b>boost</b> you and create replays that you can learn mechanics and concepts off of?  <br />
			‣Are you looking for a coach? Or are you looking to have a bot <b>raise your behavior score?</b><br />
			‣Or are you looking for a cheat for Dota 2?<br /> <br />
			
			At DOTA2 Services™ can help you with any of these services for all servers no matter the MMR. <br />
			Our professional services are <b>100% Safe, Fast, and Secure.</b> <br />
			Learn more about our services by clicking any of the <b>cards</b> below.<br />
			</p>
		</div>
		);


}

export default MainDescription;