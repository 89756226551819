import React from 'react'
import CardItem from './CardItem'
import './Cards.css'

function Cards() {
	return(
		<div className='cards'>
			<h1>Check out these services!</h1> <br />

			<div className="cards__container">
				<div className="cards__wraper">
					<h2>Boosting Services</h2>
					<ul className="cards__items">
						<CardItem
							src={'https://tse2.mm.bing.net/th?id=OIP.iYSvbIJNHhZZKvv23-tlpAHaEK&pid=Api&P=0&w=302&h=171'}
							text="MMR Booster"
							label="Boosting"
							path='/dota2/'
						 />
						 <CardItem
							src={process.env.PUBLIC_URL + '/images/dota2BehaviorScoreBad.jpg'}
							text="Behavior Score Boosting (Bot / Automated)"
							label="Behavior Score"
							path='/dota2/'
						 />
						 <CardItem
							src={process.env.PUBLIC_URL + '/images/dota2BehaviorScoreGood.jpg'}
							text="Behavior Score Boosting (Real Player)"
							label="Behavior Score"
							path='/dota2/'
						 />
					</ul>
					<h2>Purchase our Cheat</h2>
					<ul className="cards__items">
						 <CardItem
							src={'https://user-images.githubusercontent.com/65406898/96032843-e47de700-0e24-11eb-8571-899f4ce527b1.jpg'}
							text="Purchase a Compiled Dota 2 Cheat"
							label="Cheat"
							path='/dota2/'
						 />
					</ul>
					<h2>Coaching Services</h2>
					<ul className="cards__items">
						<CardItem
							src={'https://tse3.mm.bing.net/th?id=OIP.23bCYKJ8DLJXlRxzhMicTwAAAA&pid=Api&P=0&w=300&h=300'}
							text="Get Coached"
							label="Get Coached"
							path='/dota2/'
						 />
						 <CardItem
							src={'https://i.imgur.com/8NAc2he.png'}
							text="Become a Coach"
							label="Become a Coach"
							path='/dota2/'
						 />
					</ul>
				</div>
			</div>
		</div>
	);

}


export default Cards;